.worktime-container {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.work-time-header {
  color: #fff;
  margin: 0;
  padding: 40px 0 40px 0;
  z-index: 5;
}

.button-and-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 60%;
}

.card-container {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card {
  width: 30%;
  height: fit-content;
  margin: 20px 0 10px 0;
  box-sizing: border-box;
  z-index: 5;
}

@media (max-width: 1140px) {
  .card {
    width: 50%;
  }
}

@media (max-width: 727px) {
  .card {
    width: 60%;
  }
}

@media (max-width: 605px) {
  .card {
    width: 100%;
  }
}
