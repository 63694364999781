.dialog-content {
  text-align: right;
}

.dialog-box {
  margin-top: 1em;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin-bottom: 1em;
}

.dialog-box:last-child {
  margin-bottom: 0;
}

.form-label {
  font-weight: bold;
  color: #333;
}

.input-field {
  margin-left: 10px;
  padding: 0.5em;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.error-message {
  color: red;
  font-weight: bold;
}