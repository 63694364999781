.callendar-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  justify-content: space-between;
}

.callendar-bar-input {
  display: flex;
  flex-wrap: wrap;
}
.input-with-label {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.callendar-bar-label {
  margin-right: 10px;
}

.callendar-bar-time {
  display: flex;
}
