.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
}

.dialog-title {
    font-weight: bold;
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.table-cell {
    padding: 15px 20px;
    border: 2px solid #ddd;
}


.label {
    font-weight: bold;
    color: #5b5fc7;
}

.value {
    color: #555;
}

.table-cell.label {
    background-color: #5b5fc7;
    color: #ecf0f1;
    font-weight: bold;
}

.table-cell.value {
    background-color: #ecf0f1;
    color: #2c3e50;
}

.spinner-style {
    width: 100%;
    height: 100px;
}