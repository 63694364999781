.activity-status-spinner {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.activity-status-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
