.desktopLayoutContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #393c7b;
  padding: 0 40px;
}

.button-container {
  padding: 20px 10px;
}

.content {
  display: flex;
  height: 100%;
  z-index: 5;
  overflow: auto;
}

.width50 {
  width: 50%;
  display: flex;
  flex-direction: column;
}
