.event {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.entry {
  border-left: 5px solid #5ed3a4;
}

.exit {
  border-left: 5px solid #f49a8f;
}

.time {
  font-size: 14px;
  color: #555;
  float: right;
}

.title {
  font-weight: bold;
  margin-bottom: 5px;
}

.description,
.date {
  font-size: 12px;
  color: #777;
}
