.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.timeline {
  padding: 0px 10px;
  box-sizing: border-box;
}

.inAndOutHistoryInformation {
  text-align: center;
  color: #fff;
  line-height: 1;
}

.inAndOutHistoryList {
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
}

/* SCROLLBAR */

.inAndOutHistoryList::-webkit-scrollbar {
  width: 10px;
}

.inAndOutHistoryList::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.inAndOutHistoryList::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.inAndOutHistoryList::-webkit-scrollbar-track {
  background: #2c2c54;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  margin-right: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background: #2c2c54;
  border-radius: 10px;
}
