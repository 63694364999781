.status-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  justify-content: space-between;
}

.status {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.status:last-child {
  margin-right: 0;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.green {
  background-color: #4caf50;
}

.red {
  background-color: #f44336;
}

.label {
  font-size: 16px;
  color: #333;
}

.activityStatusInput {
  margin-left: 10px;
}

@media (max-width: 1150px) {
  .activityStatusInput {
    margin: 10px 0 0 0;
    width: 100%;
    display: block;
  }

  .status {
    margin: 0;
  }
}
