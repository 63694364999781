@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

* {
    font-family: 'Roboto', sans-serif !important;
}
.fui-CardPreview {
    max-width: 93vw!important;
    padding: 1rem;
}
.fui-TableBody div div{
   height: fit-content!important;
}
.fui-TableCell, .fui-TableHeaderCell {
    min-width:110px!important;
}
.main-table-container {
    width: 100%!important;
    overflow-x: auto;
}
.table-container {
    max-width: 93vw;
}
.table-empty-value {
    width: 100%;
    text-align: center;
}
body {
    background-color: white;
}
@media screen and (max-width: 965px) {
    .table-container {
        width: 93vw;
        .fui-Table {
            min-width: 100%!important;
        }
    }
}
