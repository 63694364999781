.scroll-link {
    display: inline-block;
    color: #0078d4;
    font-size: 1.5em;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 2px; 
    transition: background-color 0.3s, color 0.3s;
}

.scroll-link:hover {
    color: #004e8a;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.icon-align-middle {
    vertical-align: middle;
}