.select-role-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 15;
    color: black;
    position: absolute;
    right: 0.4125em; 
    margin-right: 0.25em;
    margin-top: 0.75em;
    font-weight: bold;
}

.role-label {
    color: white;
}

#user-role-select {
    box-shadow: outset 0 0 0.3125em 0.0625em #000000;
    font-size: 1em;
}

@media (max-width: 966px) {
    .select-role-container {
        left: 0.625em; 
        top: 0em; 
        padding: 0.75em; 
        right: auto; 
        align-items: center;
        margin-top: 0em;
    }

    .role-label {
        font-size: 0.975em; 
        color: black;
        font-weight: bold;
        display: flex;
        align-items: center;
        height: 100%;
    }
}